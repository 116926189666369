import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-wsg.svg"
import ogimage from "../../../images/projekte/wsg-strategiefilm/wsg-strategiefilm-hero.jpg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/wsg-strategiefilm/wsg-strategiefilm.mp4'
import * as projekteStyles from "../projekte.module.css"
import Video from "../../../components/video"
import SectionWrap from "../../../components/sectionWrap"
import Specs from "../../../components/specs"
import YtVideo from "../../../components/ytvideo"


const WSGStrategiefilm = function (props, ref) {

    const data = useStaticQuery(graphql`
        query WSGStrategiefilmQuery {
            heropic: file(relativePath: { eq: "images/projekte/wsg-strategiefilm/wsg-strategiefilm-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/wsg-strategiefilm/wsg-strategiefilm-hero.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Imagevideo"] },
        { title: "Leistungen", objects: ["konzept", "design"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "wordpress"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="WSG Strategiefilm 2030"
                description="Q:marketing realisiert Imagefilm für die WSG Wohnungs- und Siedlungs-GmbH."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
            
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Strategie 2030</h1>                 
                        <p className="introtext whitetextshadow">Dies WSG hat einen Plan. Imagevideo über Fragen und Antworten zu sozialen Herausforderungen in der Wohnungswirtschaft.</p>
                        <AnchorLink to="/projekte/corporate-communications/wsg-strategiefilm/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>               


            {/* <SectionWrap bgCol="#ffffff" className={`fullHeight`}>
                <div className="row">
                    <div className="col-12">
                        <Video
                            videoSrcURL={strategiefilm}
                            videoTitle="wsg Strategiefilm 2030"
                            poster={ogimage}
                        />
                    </div>
                </div>
            </SectionWrap> */}

            {/* Video */}
            <ListenModule headline="Strategiefilm">
                <div className="row">
                    <YtVideo videoId="U0BmG4ul2Qk"/>                
                </div>
            </ListenModule>            


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Entwicklung eines Imagefilms zur Kommunikation der Unternehmensvision der wsg.",
                    ]}
                />
            </ListenModule>
            

            <Specs data={specsData} />
          
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="christian-frieling"/>

        </Layout>
    )
}

export default WSGStrategiefilm